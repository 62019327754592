import styled from "@emotion/styled";

export const TitleContainer = styled.div`
    width: 100%;
    padding-top: 160px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px){
        flex-direction: column;
    }
`

export const SkillsTitleContainer = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px){
        flex-direction: column;
    }
`

export const Title = styled.div`
    font-size: 60px;
    font-weight: bold;
    color: snow;
    text-shadow:
    1px 1px 0 #777,
    2px 2px 0 #777,
    3px 3px 0 #777,
    4px 4px 0 #777,
    5px 5px 0 #777;
    display: flex;
    justify-content: center;
    @media (max-width: 768px){
        font-size: 40px;
    }
`

export const EducationContainer = styled.div`
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
`

export const EducationContentBox = styled.div`
    flex: 1;
    margin: 20px;
    padding: 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: start;
    box-sizing: border-box;
    background-color: #FFF2F2;
    box-shadow: 0px 4px 12px #FFF2F2;
    border-radius: 30px;
    @media (max-width: 768px){
        height: 300px;
        padding: 12px;
    }
`

export const ContentTitle = styled.div`
    font-size: 32px;
    color: black;
    text-shadow:
    1px 1px 0 #777;
    border-bottom: 2px solid black;
    @media (max-width: 768px){
        font-size: 24px;
    }
`

export const EducationContent = styled.div`
    font-size: 24px;
    color: black;
    margin-top: 20px;
    @media (max-width: 768px){
        font-size: 14px;
    }
`

export const SkillsContainer = styled.div`
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
`

export const ContentBox = styled.div`
    flex: 1;
    margin: 20px;
    padding: 20px;
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    background-color: #FFF2F2;
    box-shadow: 0px 4px 12px #FFF2F2;
    border-radius: 30px;
    @media (max-width: 768px){
        height: 300px;
        padding: 10px;
    }
`

export const Content = styled.div`
    width: 15%;
    margin: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    @media (max-width: 768px){
        font-size: 32px;
        width: 25%;
    }
`

export const SkillTitle = styled.div`
    font-size: 20px;
    @media (max-width: 768px){
        font-size: 14px;
    }
`