import { SiSpring, SiJavascript, SiTypescript, SiHtml5, SiCss3, SiReact, SiReactquery, SiGraphql, SiDocker, SiKubernetes, SiGit, SiJenkins, SiLinux } from 'react-icons/si';
import { FaJava } from 'react-icons/fa';
import { EducationContentBox, EducationContainer, Content, ContentBox, SkillsContainer, SkillsTitleContainer, Title, TitleContainer, SkillTitle, ContentTitle, EducationContent } from "./About.style";

export default function About(){
    return (
        <>
            <TitleContainer>
                <Title>Education</Title>
            </TitleContainer>
            <EducationContainer>
                <EducationContentBox>
                    <ContentTitle>Centennial College</ContentTitle>
                    <EducationContent>
                        Diploma, IT Software Engineering Technician (GPA: 4.19)
                    </EducationContent>
                    <EducationContent>
                        Toronto, ON, Canada (2021. Sept - 2023. April)
                    </EducationContent>
                    <EducationContent>
                        Java, JavaScript, TypeScript, React, Oracle, MongoDB, Linux, Git
                    </EducationContent>
                </EducationContentBox>
            </EducationContainer>
            <SkillsTitleContainer>
                <Title>Skills</Title>
            </SkillsTitleContainer>
            <SkillsContainer>
                <ContentBox>
                    <Content>
                        <SiHtml5/>
                        <SkillTitle>
                            HTML5
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiCss3/>
                        <SkillTitle>
                            CSS3
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiJavascript/>
                        <SkillTitle>
                            JavaSciprt
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiTypescript/>
                        <SkillTitle>
                            TypeSciprt
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiReact/>
                        <SkillTitle>
                            React
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiGraphql/>
                        <SkillTitle>
                            Graphql
                        </SkillTitle>
                    </Content>
                    <Content>
                        <FaJava/>
                        <SkillTitle>
                            Java
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiSpring/>
                        <SkillTitle>
                            Spring
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiDocker/>
                        <SkillTitle>
                            Docker
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiGit/>
                        <SkillTitle>
                            Git
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiJenkins/>
                        <SkillTitle>
                            Jenkins
                        </SkillTitle>
                    </Content>
                    <Content>
                        <SiLinux/>
                        <SkillTitle>
                            Linux
                        </SkillTitle>
                    </Content>
                </ContentBox>
            </SkillsContainer>
        </>
    )
}